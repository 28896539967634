import * as React from "react";
import Layout from "../organisms/Layout";
import { useState } from "react";
import SubscribeMailist from "../organisms/SubscribeMailist";
import HomeHero from "../organisms/HomeHero";
import BlogCard from "../organisms/BlogCard";
import { graphql, PageProps } from "gatsby";
import Wrapper from "../atoms/Wrapper";
import Container from "../atoms/Container";
import Text from "../atoms/Text";
import { PageSEO } from "../atoms/SEO";
import LatestBlog from "../organisms/LatestBlog";
import HomeBlogsList from "../organisms/HomeBlogsList";
import HomeCategories from "../organisms/HomeCategories";
import SearchBar from "../molecules/SearchBar";
let CATEGORISED_BLOGS: {
  [key: string]: any[];
};
function addCategory(cat: string, item: any) {
  if (!CATEGORISED_BLOGS[cat]) CATEGORISED_BLOGS[cat] = [item];
  else CATEGORISED_BLOGS[cat].push(item);
}
const IndexPage = ({
  data: {
    allSanityPost: { nodes: blogs },
  },
  location,
}: PageProps<Queries.HomeQuery>) => {
  const [showWaitListForm, setShowWaitListForm] = useState(false); //to keep track of visibility of modal
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  if (!CATEGORISED_BLOGS) {
    CATEGORISED_BLOGS = {};
    blogs.forEach((b) => {
      addCategory("All", b);
      b.categories?.forEach((c) => c?.title && addCategory(c.title, b));
    });
  }
  const filteredBlogs = CATEGORISED_BLOGS[selectedCategory].filter((b) =>
    b.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  function displayWaitListForm() {
    //display the wait-list modal/form by setting showWaitListFrom to true
    setShowWaitListForm(true);
  }

  function hideDisplayWaitListForm() {
    //hide the wait-list modal/form by setting showWaitListFrom to false
    setShowWaitListForm(false);
  }

  return (
    <Layout
      //@ts-ignore
      pathname={location.pathname}
      displayWaitListForm={displayWaitListForm}
      hideDisplayWaitListForm={hideDisplayWaitListForm}
      showWaitListForm={showWaitListForm}
    >
      <PageSEO
        title={"SaaSPay Resources"}
        description={
          "The best place to learn about B2B SaaS and cloud Buy-Now-Pay-Later solutions-SaaSPay"
        }
        path={location.pathname}
      />
      <HomeHero />
      <div className={"mt-10 md:mt-16"}>
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <HomeCategories
          CATEGORISED_BLOGS={CATEGORISED_BLOGS}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        {searchTerm === "" && <LatestBlog featureBlog={blogs[0]} />}
        <HomeBlogsList searchTerm={searchTerm} blogs={filteredBlogs} />
      </div>

      <SubscribeMailist />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query Home {
    allSanityPost {
      nodes {
        title
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData
          }
        }
        author {
          name
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        body {
          _rawChildren
          _type
          list
          style
          _key
          children {
            _type
            _key
            marks
            text
          }
        }
        publishedAt
        categories {
          title
        }
      }
    }
  }
`;
