import React from "react";
import Wrapper from "../../atoms/Wrapper";
import Container from "../../atoms/Container";
import Tag from "../../atoms/Tag";
import Text from "../../atoms/Text";
import SubscribeFormInput from "../../atoms/SubscribeFormInput";
import HomeHeroCaricature from "../../images/HomeHero";

export default function HomeHero() {
  return (
    <Wrapper className={"bg-p2 "}>
      <Container
        className={
          "flex flex-wrap md:flex-nowrap gap-y-8 md:gap-x-10 lg:gap-x-[137px] pb-4 md:pb-11 pt-7 md:pt-[52px] items-center"
        }
      >
        <div className={"md:w-[438px] mt-20 md:mt-28"}>
          <h1 className={"mb-2 md:mb-4 md:text-start text-center"}>
            <h1 className={"sr-only"}>Get-Set-Grow your SaaS</h1>
            <Text
              text={"Get-Set-Grow your SaaS"}
              className={
                "text-[24px] sm:text-[32px] md:text-[36px] lg:text-[50px]"
              }
              textStyle={"h1"}
            />
          </h1>
          <div className="leading-7 md:leading-9 mb-6 md:mb-11 md:text-start text-center">
            <Text
              text={
                "Inside scoop from the world of SaaS, industry roundups and business breakdowns is now just a click away. Don't miss out on the opportunity to stay ahead of the curve."
              }
              textStyle={"inter-400-20-24"}
              className={"#4E5D78"}
            />
          </div>
          <div className="">
            <div className="mb-2 md:mb-5">
              <Text
                className={"text-base md:!text-lg"}
                textStyle={"inter-500-16-24"}
                text={"Sign up today and stay informed!"}
              />
            </div>
            <div className="">
              <SubscribeFormInput
                variant={"primary"}
                inputPadding={30}
                buttonWidth={145}
                height={68}
                rounded={false}
              />
            </div>
          </div>
        </div>
        <div className="mx-auto md:mx-0 flex-grow">
          <HomeHeroCaricature />
        </div>
      </Container>
    </Wrapper>
  );
}
