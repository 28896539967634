import React from 'react';
import Wrapper from "../../atoms/Wrapper";
import Container from "../../atoms/Container";

type PROPS_STYLE={
    searchTerm:string,
    setSearchTerm:(x:string)=>void
}

export default function SearchBar(props:PROPS_STYLE){
    return <Wrapper className={''}>
        <Container className={''}>
            <div className={'flex rounded-lg w-full mb-5 border-[1px] border-[#F4F4F4] bg-white px-12 py-5 items-center'}>

                <input value={props.searchTerm} onChange={(e)=>props.setSearchTerm(e.target.value)}  placeholder={'search anything'} className={'outline-none flex-grow  shadow-none p-0 '}/>
                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9166 13.0792L9.42603 9.43093C10.2899 8.42851 10.8208 7.10683 10.8208 5.65474C10.8207 2.53674 8.39365 0 5.41038 0C2.42711 0 0 2.53674 0 5.65477C0 8.77279 2.42711 11.3095 5.41038 11.3095C6.79972 11.3095 8.06425 10.7546 9.02338 9.8518C9.02338 9.8518 12.4583 13.4419 12.5139 13.5C12.5695 13.5581 12.6424 13.5872 12.7153 13.5872C12.7881 13.5872 12.861 13.5581 12.9166 13.5C13.0278 13.3837 13.0278 13.1954 12.9166 13.0792ZM5.41038 10.7143C2.74135 10.7143 0.569531 8.44463 0.569531 5.65477C0.569531 2.8649 2.74135 0.595228 5.41038 0.595228C8.07941 0.595228 10.2512 2.86487 10.2512 5.65477C10.2512 8.44466 8.07941 10.7143 5.41038 10.7143Z" fill="#2E2E2E"/>
                </svg>

            </div>
        </Container>
    </Wrapper>
}
