import React from 'react';
import Wrapper from "../../atoms/Wrapper";
import Container from "../../atoms/Container";
import Text from "../../atoms/Text";
import {GatsbyImage} from "gatsby-plugin-image";
import {Link, PageProps} from "gatsby";
import Tag from "../../atoms/Tag";

type PROPS_STYLE={
    featureBlog: PageProps<Queries.HomeQuery['allSanityPost']['nodes'][0]>['data'],

}

export default function LatestBlog(props:PROPS_STYLE){
    const compiledImage=props.featureBlog.mainImage?.asset?.gatsbyImageData;
    const authorCompiledImage=props.featureBlog.author?.image?.asset?.gatsbyImageData;
    let date="Few days back";
    if(props.featureBlog.publishedAt) date=new Date(props.featureBlog.publishedAt).toDateString().toUpperCase();
    const authorName=props.featureBlog.author?.name||'Team SaaSPay';
    const blockquote = props.featureBlog.body?.find(block=>block?.style==='blockquote'&&block.children?.at(0)?.text?.includes('[description]'))
    let description=blockquote?.children?.at(0)?.text?.split('[description]')[1]||"A SaaSPay Blog";
    return <Wrapper className={''}>
        <Container className={'pb-9 md:pb-16'}>
            <div style={{width:75, height:9}} className={'bg-p1 mb-4'}/>
            <div className={'mb-4 md:mb-9'}>
                <Text component={'h2'} text={'Latest'} textStyle={'h2'} className={'text-[#323B4B]'}/>
            </div>
            {props.featureBlog.slug?.current&&<Link to={props.featureBlog.slug?.current} className={'flex flex-wrap md:flex-nowrap gap-5 md:gap-8 cursor-pointer'} >
                { compiledImage && <GatsbyImage className={'max-w-[738px]'} image={compiledImage} alt={props.featureBlog.title||''}/>}
                <div className={'md:max-w-[375px]'}>
                    <div className="mb-2 md:mb-6">
                        <Text component={'h3'} text={props.featureBlog.title||''} textStyle={'h3'} className={'text-[24px] md:text-[30px]'}/>
                    </div>
                    <div className="mb-4 md:mb-9">
                        <Text text={description} textStyle={'inter-400-20-24'} className={'text-base md:text-lg'}/>
                    </div>
                    <div className="mb-6 flex">
                        <Tag color={"#9D43A5"} text={props.featureBlog.categories&&props.featureBlog.categories[0]?.title||''}/>
                    </div>
                    <div className="capitalize flex gap-2">
                        { authorCompiledImage && <GatsbyImage className={'w-8 h-8 rounded-full overflow-hidden'} image={authorCompiledImage} alt={props.featureBlog.title||''}/>}

                        <Text text={authorName.toUpperCase()+' . '+date} textStyle={'inter-600-16-29 !text-[#979797]'}/>
                    </div>

                </div>
            </Link>}

        </Container>
    </Wrapper>
}
