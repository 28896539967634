import React from 'react';
import Text from "../../atoms/Text";
import Wrapper from "../../atoms/Wrapper";
import Container from "../../atoms/Container";

type PROPS_STYLE = {
    CATEGORISED_BLOGS: {
        [key: string]: any[]
    }, selectedCategory: string, setSelectedCategory: (x: string) => void
}

export default function HomeCategories({CATEGORISED_BLOGS, selectedCategory, setSelectedCategory}: PROPS_STYLE) {
    return <Wrapper className={''}>
        <Container className={'mb-7 px-5 md:mb-11 flex flex-nowrap gap-x-7 md:gap-x-10 overflow-auto'}>
            {CATEGORISED_BLOGS && Object.keys(CATEGORISED_BLOGS).map(c =>
                <div key={c} onClick={() => setSelectedCategory(c)} className={'relative md:flex-grow-0 flex-grow flex-shrink-0 md:flex-shrink'}>
                    {selectedCategory === c && <span className={'absolute top-1/2 -translate-y-1/2 -left-[13px] w-2 h-2 bg-p1 rounded-full'}/>}
                    <Text text={c} textStyle={'inter-600-16-29'} className={'!text-[14px] cursor-pointer flex-shrink-0' + (selectedCategory === c ? "!text-p1" : "")}/>
                </div>)}
        </Container>
    </Wrapper>
}
