import React from 'react'

export default function HomeHeroCaricature(){
    return <svg className={'w-full'} version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 527 308" width="527" height="408">
        <path id="Layer" fill="#ffffff"
              d="m373.6 244.5c-25.4 17.9-70.9 35.2-122.2 42.7-66.5 9.7-142.8 3-197.9-40.2q-4.9-3.8-9.5-7.9c-108.8-98.4 3.8-259.6 124.4-191.5 77.3 43.7 114.2 59.6 214.4 11.1 81.4-39.3 137.3 82.8-9.2 185.8z"/>
        <g className={'shape-1'} id="person">
            <path id="Layer" fill="#ffffff"
                  d="m304 191.5c-1.2 3.6-18.4 5.3-24.8 5.8l0.1 1c5.5 0 18.2 0.4 18.2 4.3 0 4.8-19.7 4.5-19.7 4.5-5.7 11.8-18 13.1-18.5 13.2-0.5 0.1-1.9 3.9-2.3 5.9 2.5 0.7 3.9 1 3.9 1 0 0-2.1 6.1-3.5 10.7-1.3 4.6-1.8 7.9 0.8 11.1 3.8 4.5-0.9 21.8-6.8 38.2-66.5 9.7-142.8 3-197.9-40.1 16.5-44.7 42-72.7 47.5-83.5 1.4-2.8 5-33.2 3.2-51.7-4.9 2.1-14.7 4.2-19.5-1-6.4-6.9 2.6-16.5 10.9-14.7h0.1c0.1 0.1 0.2 0.1 0.2 0.1 2.7 0.7 4.6 3 5.5 5 1.3 2.8 5.4 5.2 6.3-0.4 0.8-5.6 3.3-6.2 4.5-7.1 1.2-0.9 2.4-6.4 0-8.4 3.3 0.9 8.3-1.5 7.9-7 3 2.5 10.4 2.1 12.8-3.7 3 3 6.3 3 8.8 1.8 19.2 10 38.2 21.2 38.1 22.8-0.1 1.6-23.2 11.3-23.2 11.1 0-0.2-1.3 50.9-0.6 53.6 12.1 12.9 28.1 33.7 41 88.3 0 0.1 0 0.1 0 0.2 0.1 0.2 0.2 0.4 0.2 0.6 1.8 7.5 6.3 14 13.7-0.2q0 0 0 0.2c5.9-11.3 11.6-20.2 14.3-19.7 0 0 2.2-10.5 5.7-21.7 2-6.5 4.5-13.1 7.3-18.2 11.3-20.6 23.6-28.8 40.8-33.6 19.5-5.6 23.7 4.2-3.9 17.4 9.4-2.3 22-3.1 25.1 0.4 4.7 5.2-12 8.4-20 9.6 13.5-0.8 25.3-0.3 23.8 4.2z"/>
            <path id="Layer" fill="#202d37"
                  d="m47.9 265.6q-0.2 0-0.4-0.1c-0.7-0.2-1-0.9-0.8-1.5 11.8-41.6 32.5-70.9 44.9-88.4 3.8-5.4 6.9-9.7 8.3-12.6 1.1-2.2 4.7-30.3 3.2-49.3-5.3 1.9-14.5 3.4-19.3-1.9-3.1-3.3-3.3-7.8-0.7-11.6 2.8-4 8.9-7 14.6-4.6 0.6 0.2 0.9 1 0.6 1.6-0.2 0.7-0.9 1-1.6 0.7-4.5-1.9-9.3 0.5-11.5 3.7-1 1.4-2.8 4.9 0.4 8.5 4.1 4.4 13.1 2.8 18.1 0.6 0.4-0.1 0.8-0.1 1.2 0.1 0.3 0.2 0.6 0.5 0.6 0.9 1.7 18.2-1.7 49.1-3.3 52.4-1.6 3.1-4.7 7.4-8.6 13-12.3 17.3-32.8 46.4-44.5 87.5-0.2 0.6-0.7 1-1.2 1z"/>
            <path id="Layer" fill="#202d37"
                  d="m88.5 107.2c-0.5 0-0.9-0.3-1.1-0.8-0.2-0.6 0.1-1.3 0.7-1.6 4.7-1.9 7.5-1.9 8.9-1.9 0.7 0 1.3 0.6 1.3 1.3 0 0.6-0.6 1.2-1.3 1.2-1.3 0-3.7 0-8 1.7q-0.3 0.1-0.5 0.1z"/>
            <path id="Layer" fill="#202d37"
                  d="m128.7 100.2c-0.9 0-1.6-0.7-1.6-1.5v-3.9c0-0.8 0.7-1.5 1.6-1.5 0.8 0 1.5 0.7 1.5 1.5v3.9c0 0.8-0.7 1.5-1.5 1.5z"/>
            <path id="Layer" fill="#202d37"
                  d="m141.6 95.7c-0.9 0-1.6-0.7-1.6-1.6v-3.8c0-0.8 0.7-1.5 1.6-1.5 0.8 0 1.5 0.6 1.5 1.5v3.9c0 0.8-0.7 1.5-1.5 1.5z"/>
            <path id="Layer" fill="#202d37"
                  d="m129.2 116.2c0 0-0.1 0 0 0-6.2-0.1-10.7-4-11.9-10.2-0.1-0.7 0.3-1.3 1-1.5 0.7-0.1 1.3 0.3 1.5 1 1 5.1 4.5 8.2 9.4 8.3 0.7 0 1.2 0.5 1.2 1.2 0 0.7-0.6 1.2-1.2 1.2z"/>
            <path id="Layer" fill="#202d37"
                  d="m200.1 267.7c-0.5 0-1.1-0.4-1.2-1-12.7-62.1-29.3-86.4-43.8-101.8q-0.2-0.3-0.2-0.6c-0.2-0.5-0.5-1.8 0-28.2 0.1-6.8 0.2-13.4 0.3-17.8 0.1-2.4 0.1-4.4 0.2-5.8 0-0.9 0-1.6 0-2 0 0 0-0.1 0-0.2h0.1c0-0.6 0.1-0.7 0.4-0.9q0.3-0.2 0.6-0.3c2-0.6 18.2-7.7 21.7-10-3.5-3.1-22.2-14.1-42.1-24.2-0.6-0.3-0.8-1.1-0.5-1.7 0.3-0.6 1.1-0.8 1.7-0.5 9.9 5 20.9 11.1 29.4 16.1 14.6 8.5 14.5 9.6 14.4 10.5 0 0.9-0.1 1.8-12.2 7.2-0.1 0.1-7.8 3.6-11 4.7-0.1 6.7-1.1 47.8-0.6 52.2 14.7 15.8 31.4 40.4 44.1 102.8 0.2 0.7-0.2 1.3-0.9 1.5-0.2 0-0.3 0-0.4 0z"/>
            <path id="Layer" fill="#202d37"
                  d="m136.8 183.8c-3 0-7.9-1.5-13.4-4.1-5.2-2.5-13.4-7.1-23.2-15.2-0.5-0.4-0.6-1.2-0.1-1.7 0.4-0.6 1.2-0.6 1.7-0.2 17.3 14.4 31.8 19.2 35.6 18.6 3.1-0.4 12.4-11.3 17.7-18 0.4-0.5 1.2-0.6 1.7-0.2 0.5 0.4 0.7 1.2 0.2 1.8-2.3 3-14.3 18.1-19.2 18.9-0.3 0.1-0.7 0.1-1 0.1z"/>
            <path id="Layer" fill="#202d37"
                  d="m143.3 202c0-1.3 1.1-2.4 2.4-2.4 1.4 0 2.5 1.1 2.5 2.4 0 1.4-1.1 2.5-2.5 2.5-1.3 0-2.4-1.1-2.4-2.5z"/>
            <path id="Layer" fill="#202d37"
                  d="m148.5 226.7c1.3 0 2.3 0.9 2.3 1.9 0 1-1 1.8-2.3 1.8-1.3 0-2.3-0.8-2.3-1.8 0-1 1-1.9 2.3-1.9z"/>
            <path id="Layer" fill="#202d37"
                  d="m151.7 257.5c0.7 1.1 0.5 2.4-0.4 2.9-0.9 0.5-2.1 0-2.7-1.1-0.7-1.1-0.5-2.4 0.4-2.9 0.9-0.5 2.1 0 2.7 1.1z"/>
            <path id="Layer" fill="#202d37"
                  d="m112.1 196.5q-0.9 0-1.7-0.3c-4-1.4-6-7.5-8.5-15.1-0.7-1.9-1.4-4-2.1-6.1-0.2-0.6 0.1-1.4 0.7-1.6 0.7-0.3 1.4 0.1 1.6 0.7 0.8 2.2 1.5 4.2 2.2 6.3 2.2 6.6 4.1 12.4 6.9 13.5 1 0.3 2.2 0.1 3.6-0.8 4.6-2.9 10.2-7.8 10.2-7.8 0.5-0.5 1.4-0.5 1.8 0.1 0.4 0.5 0.4 1.3-0.1 1.7-0.3 0.2-5.8 5.1-10.6 8.1-1.3 0.9-2.7 1.3-4 1.3z"/>
            <path id="Layer" fill="#202d37"
                  d="m160.7 190.2c-2.1 0-5.9-1.1-13.8-5.7-0.6-0.3-0.8-1.1-0.5-1.7 0.4-0.6 1.1-0.8 1.7-0.4 10.3 6 12.8 5.4 13.2 5.2 0.1 0 0.2 0 0.2-0.1 0.8-3.3 1.9-13.4 1.9-13.5 0.1-0.7 0.7-1.2 1.4-1.1 0.6 0.1 1.1 0.7 1.1 1.4-0.1 0.4-1.2 10.4-2 13.8-0.2 0.9-0.8 1.5-1.6 1.9-0.4 0.1-0.9 0.2-1.6 0.2z"/>
            <path id="Layer" fill="#202d37"
                  d="m144.4 267.7c0 0 0 0-0.1 0-0.6 0-1.2-0.6-1.1-1.3 0.2-3.8 0.2-14.4-1.7-17.2-2.3-3.3-3.7-8.7-0.9-13.8 2.5-4.5 0.9-9.5-1-13.8-2.3-5.2-2.1-18.6-1.1-29.5 0.1-0.7 0.7-1.1 1.4-1.1 0.6 0.1 1.1 0.7 1.1 1.3-1.2 13.3-0.9 24.3 0.8 28.3 2.2 4.8 4 10.5 1 16-2.3 4.1-1.1 8.4 0.7 11.1 2.8 4.1 2.2 17.3 2.2 18.8-0.1 0.7-0.7 1.2-1.3 1.2z"/>
            <path id="Layer" fill="#202d37"
                  d="m208.3 259.7c-0.1 0-0.3-0.1-0.5-0.2-0.6-0.2-0.9-1-0.6-1.6 2.4-5.6 5.9-12.1 9.2-17.2 4.2-6.2 6.4-8 7.8-8.5 1-4.7 5.9-26.7 12.9-39.3 12.2-22.2 25.4-29.7 41.6-34.3 9.3-2.6 15.7-2 17 1.6 1.7 4.6-5.8 11.3-20 18.2-0.6 0.3-1.4 0-1.7-0.6-0.3-0.6 0-1.3 0.6-1.6 15.2-7.3 19.6-13 18.8-15.1-0.5-1.4-4.8-2.7-14.1-0.1-15.5 4.4-28.3 11.7-40 33.1-7.5 13.6-12.8 39.3-12.9 39.5-0.1 0.6-0.7 1.1-1.4 1-0.5 0.1-2.7 1.7-6.7 8-3.2 4.9-6.5 11-8.8 16.3-0.2 0.5-0.7 0.8-1.2 0.8z"/>
            <path id="Layer" fill="#202d37"
                  d="m250.7 245.8c-0.1 0-0.2 0-0.3 0-17.8-4.3-25.7-11.2-26-11.5-0.5-0.5-0.6-1.3-0.1-1.8 0.5-0.5 1.2-0.5 1.7-0.1 0.2 0.1 7.9 6.8 25 10.9 0.6 0.2 1 0.9 0.9 1.5-0.2 0.6-0.7 1-1.2 1z"/>
            <path id="Layer" fill="#202d37"
                  d="m258 267.7q-0.2 0-0.3 0c-0.7-0.2-1.1-0.9-0.9-1.5 3.1-11.5 2.2-13.7 0.4-16.6-2.5-4.1-2.2-7.7-0.9-12.1 0.9-3.3 2.3-7.3 3-9.4-4.4-1.1-18.1-5.2-29.3-15.5-0.5-0.5-0.5-1.3 0-1.8 0.4-0.5 1.2-0.5 1.7-0.1 12.6 11.7 29.3 15.3 29.5 15.3 0.3 0.1 0.6 0.3 0.8 0.6q0.3 0.5 0 1c0 0.1-2.1 6.1-3.4 10.6-1.4 4.6-1.2 7.1 0.6 10.1 2.6 4.1 2.9 7.4-0.1 18.5-0.1 0.6-0.6 0.9-1.1 0.9z"/>
            <path id="Layer" fill="#202d37"
                  d="m257 227.4c-0.1 0-0.2 0-0.2 0-0.7-0.2-1.1-0.8-1-1.5 0.2-1 0.7-2.5 1.2-3.8 0.8-2.2 1.3-2.9 2.1-3 1.3-0.1 12.4-1.8 17.6-12.6 0.2-0.4 0.6-0.7 1.1-0.7 5.9 0.1 15.9-0.5 18.2-2.7 0.3-0.3 0.3-0.5 0.3-0.5 0-0.1 0-0.3-0.3-0.5-2.3-2.2-13-2.7-19.2-2.5-0.7 0-1.2-0.6-1.3-1.3 0-0.6 0.6-1.2 1.2-1.2 1.8-0.1 17.3-0.5 21 3.2 0.9 0.8 1.1 1.7 1.1 2.2 0 0.6-0.2 1.5-1.1 2.3-3.3 3.3-15.6 3.5-19.1 3.4-5.5 10.5-16.1 12.8-18.7 13.2-0.4 0.9-1.3 3.3-1.7 4.9-0.1 0.7-0.6 1.1-1.2 1.1z"/>
            <path id="Layer" fill="#202d37"
                  d="m276.5 201.5q-0.1 0.1-0.3 0c-0.6-0.2-1-0.8-0.9-1.5 0.5-2 0.4-11.5-1.7-18.6-0.2-0.7 0.2-1.4 0.9-1.5 0.6-0.2 1.3 0.1 1.5 0.8 2.1 7.2 2.4 17.4 1.8 19.9-0.2 0.6-0.7 0.9-1.3 0.9z"/>
            <path id="Layer" fill-rule="evenodd" fill="#202d37"
                  d="m276.2 188.9q-0.2 0-0.3 0c-0.5-0.1-0.9-0.5-1-1-0.1-0.7 0.4-1.4 1.1-1.5 0.1 0 0.2 0 0.3 0 0.7 0 6-0.6 11.7-1.8 9.9-2.1 11.5-4.2 11.8-4.8 0-0.2 0.2-0.6-0.5-1.3-2.8-3.2-17.8-2-27.6 1-0.6 0.3-1.3-0.1-1.5-0.8-0.3-0.6 0.1-1.3 0.8-1.5 8.5-2.8 25.9-5.2 30.2-0.4 1.6 1.9 1.2 3.5 0.7 4.3-0.5 0.9-2 3.5-14 6-4.7 1-10.4 1.8-11.7 1.8zm1.2-1.4c0-0.1 0-0.1 0-0.2 0 0.1 0 0.1 0 0.2zm-1-1q0.1 0 0.3 0.1c-0.1-0.1-0.2-0.1-0.3-0.1z"/>
            <path id="Layer" fill="#202d37"
                  d="m276.8 198.7c-0.7 0-1.2-0.5-1.3-1.1 0-0.7 0.5-1.3 1.2-1.4 0.1 0 6.5-0.3 13-1.3 12.3-1.7 13.1-3.7 13.1-3.8 0.2-0.4 0-0.6-0.1-0.7-0.6-0.9-4.2-2.9-22.4-1.9-0.7 0-1.3-0.5-1.3-1.2-0.1-0.6 0.5-1.3 1.1-1.3 19.3-1.1 23.3 1.1 24.6 2.9 0.7 0.9 0.8 1.9 0.5 3-0.6 1.6-2.8 3.7-15.2 5.5-6.6 1-13.1 1.4-13.2 1.4q0-0.1 0-0.1z"/>
            <path id="Layer" fill="#202d37"
                  d="m257 216.8c-0.4 0-0.8-0.2-1-0.5-0.4-0.6-0.3-1.4 0.3-1.8 4.6-3.1 7.2-6.8 7.9-10.8 0.8-5.3-1.9-10.3-4.4-13.5-0.2-0.3-0.2-0.7-0.2-1 0.1-0.4 0.4-0.7 0.6-0.8 4-2.2 10.1-6.8 9.8-9.9q0-0.5-0.9-0.8c-3-1.3-11.1 0-18.4 5.8-0.6 0.4-1.3 0.3-1.8-0.2-0.4-0.6-0.3-1.3 0.2-1.8 7.7-6 16.9-7.9 21-6.2 1.4 0.7 2.3 1.7 2.4 3 0.4 4.8-6.8 9.7-9.8 11.5 2.4 3.7 4.9 8.8 3.9 14.4-0.7 4.7-3.7 8.9-8.9 12.4-0.2 0.1-0.5 0.2-0.7 0.2z"/>
            <path id="Layer" fill="#202d37"
                  d="m120.1 78.4c0.4 5.5-4.6 7.9-7.9 7 2.4 2 1.2 7.5 0 8.4-1.2 0.9-3.7 1.5-4.6 7.1-0.9 5.6-4.9 3.2-6.2 0.4-1-2.1-3-4.4-5.8-5.1-6.2-1.4-5.9-8.5-2.5-10.3-11.8-4-2.2-18.7 9.2-16.3-1.9-8.7 9.4-15.2 15.7-9.1-1.2-5.6 8.3-7.3 10.7-2.4 3.3-8.2 19.9-2 12 9.4 12.1-0.3 0.9 15.8-7.8 7.2-2.4 5.8-9.8 6.2-12.8 3.7z"/>
            <path id="Layer" fill-rule="evenodd" fill="#202d37"
                  d="m105.1 105.5c-2.1 0-4-1.8-4.8-3.7-0.9-1.8-2.6-3.8-4.9-4.4-3.3-0.7-5.5-2.9-5.9-6-0.3-1.9 0.2-3.8 1.3-5.2-4.1-2.1-4.9-5.9-4.1-8.8 1.4-5.2 7.4-9.9 14.2-9.3-0.2-4 1.9-7.7 5.8-9.8 3.4-1.9 7.2-1.9 10.2-0.2 0.5-1.7 1.8-3 3.8-3.7 2.6-0.9 5.8-0.5 7.9 1.4 1.2-1.6 3.1-2.5 5.4-2.7 3.6-0.3 7.4 1.7 9.1 4.6 0.8 1.6 1.8 4.6-0.2 8.6 3 0.5 3.9 2.2 4.2 3.3 0.8 2.5-1 5.8-4.1 7.6-3.2 1.9-6.7 1.7-9.7-0.5-1.3 2-3.3 3.4-5.8 4-2.2 0.6-4.4 0.4-6.2-0.3-0.3 1.8-1.2 3.4-2.6 4.6-1.2 1-2.7 1.6-4.2 1.7q0.4 1.3 0.3 2.8c0 1.9-0.6 4.4-1.8 5.3q-0.4 0.2-0.8 0.4c-1.2 0.8-2.7 1.7-3.3 5.9-0.6 3.5-2.3 4.3-3.4 4.4q-0.2 0-0.4 0zm-5.2-35c-5.1 0-9.7 3.6-10.7 7.5-0.9 3.1 0.7 5.5 4.3 6.7 0.5 0.2 0.8 0.6 0.9 1.1 0 0.5-0.2 0.9-0.7 1.2-1.2 0.7-1.9 2.4-1.7 4.1 0.3 2 1.7 3.4 4 4 4.1 0.9 6.1 4.6 6.6 5.7 0.6 1.4 1.9 2.3 2.6 2.3 0.6-0.1 1-1 1.3-2.4 0.8-5.3 3.1-6.7 4.5-7.6q0.3-0.2 0.5-0.3c0.3-0.2 1-1.9 0.9-3.8-0.1-1.2-0.4-2.1-0.9-2.6-0.5-0.4-0.6-1-0.4-1.5 0.3-0.6 0.9-0.8 1.5-0.7 1.4 0.4 3.2-0.1 4.5-1.1 0.9-0.8 2-2.2 1.8-4.6-0.1-0.5 0.2-1 0.6-1.2 0.5-0.2 1-0.2 1.4 0.1 1.3 1.1 3.8 1.5 6 1 1.3-0.4 3.8-1.4 4.9-4.2 0.1-0.4 0.5-0.6 0.9-0.7 0.4-0.1 0.8 0 1.1 0.3 3.4 3.4 6.7 2 7.9 1.3 2.3-1.3 3.4-3.6 3-4.8-0.3-1-1.7-1.6-3.9-1.5-0.5 0-0.9-0.3-1.2-0.7-0.2-0.4-0.1-0.9 0.1-1.3 2-2.9 2.4-5.6 1.2-7.8-1.2-2.1-4.1-3.6-6.7-3.4-1.2 0.1-3.4 0.6-4.4 3-0.1 0.4-0.6 0.7-1.1 0.7-0.5 0-0.9-0.2-1.1-0.7-1-2.1-4-2.6-6.1-1.8-0.9 0.3-2.8 1.2-2.2 3.4 0.1 0.5-0.1 1.1-0.6 1.3-0.5 0.3-1.1 0.2-1.5-0.1-3-2.9-6.9-2.3-9.3-0.9-2.6 1.4-5.3 4.6-4.4 8.8 0.1 0.4 0 0.8-0.3 1.1-0.3 0.3-0.7 0.5-1.1 0.4q-1.2-0.3-2.2-0.3z"/>
        </g>
        <g id="phone" className={'shape-3'}>
            <path id="Layer" fill="#ffffff"
                  d="m516 290.8c-3.7 10.5-8.3 17.3-25.1 14.8-16.9-2.4-89.4-35-89.4-35-9 11.9-22.7 19.1-33.8 20-18.2 1.5-40.5-5.1-45.7-40.9-5.1-35.8 17.5-64.2 24.5-83.5q-0.9-0.3-1.7-0.7c-7.4-3.3-8.3-7.7-2.5-10-1.4-0.7-2.6-1.4-3.4-2.1-3.2-2.8-2.4-7.3 1.7-8.7-6.9-5.2-5.7-9.8-1.5-11.7-8.5-4.5-7-13.7 10.1-15.4 0.5-0.1 1.1-0.1 1.6-0.1-0.5-1.4-1.4-2.8-2.7-4.6-3.5-4.7-9-15.6-4.9-17.2 1.3-0.6 2-0.2 2.7 0.6l2.7-1.4c6.3-2.8 24.4-2.6 28.2 3.8 1.8 0.7 1.1 6.8-2.2 11.9-2 2.8-2.7 5.8-3 8.2 4.4 0.9 8.7 2.2 12.7 3.7-0.4-38.3-2.6-88.6-3.2-104.8-0.6-16.3 25.1-17.5 47.5-15.9 22.3 1.5 90.3 11.6 94.4 30.6 6.6 31.4-3.4 247.9-7 258.4z"/>
            <path id="Layer" fill="#202d37"
                  d="m480.3 280.2c-3.5 0-8.5-1.4-15.6-4.3-23.1-9.5-55.2-20.9-55.5-21-0.7-0.3-1-1-0.8-1.6 0.3-0.7 1-1 1.6-0.8 0.4 0.1 32.5 11.6 55.7 21.1 9.6 4 15.1 5.1 17.7 3.5 2.5-1.5 2.9-5.9 3.5-11.9q0.3-2.1 0.5-4.3c4.3-36.7 9-154.8 6.6-184.2-1.2-13.7-2.6-21-7.7-25.6-5.1-4.5-14-6.5-30.1-10.3l-8.1-1.9c-27.4-6.4-47.2-11-52.9-6.5-1.1 0.9-1.7 2.1-1.7 3.8v89.6c0 0.7-0.5 1.3-1.2 1.3-0.7 0-1.2-0.6-1.2-1.3v-89.7c0-2.5 0.8-4.4 2.5-5.8 6.6-5.3 25.9-0.7 55.1 6.1l8.1 1.9c16.5 3.8 25.6 5.9 31.1 10.9 5.8 5.1 7.3 12.8 8.5 27.2 2.4 29.5-2.3 148-6.6 184.7q-0.2 2.2-0.4 4.3c-0.7 6.7-1.2 11.6-4.8 13.8-1.1 0.7-2.5 1-4.3 1z"/>
            <path id="Layer" fill="#202d37"
                  d="m497.1 307.4q-2.9 0-6.5-0.5c-16.8-2.5-86.7-33.8-89.7-35.1-0.6-0.3-0.9-1-0.6-1.6 0.2-0.7 1-1 1.6-0.7 0.7 0.4 72.6 32.5 89.1 34.9 16.6 2.4 20.5-4.6 23.7-14 1.8-5.1 5.2-61.6 7.4-121.1 2.6-72.4 2.5-123.5-0.4-136.7-1.6-7.6-15.2-14.8-39.4-20.9-19-4.7-41.2-7.9-53.8-8.7-22.5-1.6-37 0.6-43 6.4-2.3 2.2-3.3 4.9-3.2 8.2 0.1 2.3 0.2 5.2 0.4 8.7 0.8 22.1 2.5 63.2 2.9 96.1 0 0.7-0.5 1.3-1.3 1.3-0.6 0-1.2-0.6-1.2-1.3-0.4-32.9-2-73.9-2.9-96-0.2-3.5-0.3-6.4-0.4-8.7-0.1-4 1.2-7.4 4-10.1 6.6-6.4 21.3-8.7 44.8-7.1 17.9 1.2 91 10.7 95.5 31.6 4.1 19.4 1.7 102.5 0.4 137.3-1.9 54.1-5.4 115.9-7.4 121.8-1.8 4.9-3.9 9.8-8.1 12.9-3 2.2-6.9 3.3-11.9 3.3z"/>
            <path id="Layer" fill="#00B88B"
                  d="m411 242.8c0 3.8-0.5 7.4-1.3 10.8-1.6 6.4-4.5 12.1-8.2 17-9 11.9-22.7 19.1-33.8 20-18.2 1.5-40.5-5.1-45.7-40.9-5.1-35.8 17.5-64.2 24.5-83.5 9.6 3.4 18.5-0.7 10.8-8.2 3.2-1.2 2.6-5.9 0.8-6.5l-0.1-0.7c6.4-1 7-7.5 0.7-11.3 4.9 0.6 10.1 0.3 10.1 0.3 0.9 8.5 3.5 14.2 8.1 17.9 1.8 7.6 4.2 14 6.9 19.6 11 22.2 27.1 32.6 27.2 65.5z"/>
            <path id="Layer" fill="#202d37"
                  d="m349.9 136.7c-11-0.3-16.9-5.3-17.1-10-0.2-4.2 4-9.1 16.3-10.3 0.5-0.1 1.1-0.2 1.6-0.2 0.7-0.1 1.3 0.5 1.4 1.1 0 0.7-0.5 1.3-1.2 1.4q-0.8 0-1.6 0.1c-10.5 1.1-14.1 4.9-14 7.8 0.1 3.5 5.3 7.4 14.7 7.7 0.7 0 1.2 0.6 1.2 1.2-0.1 0.7-0.6 1.2-1.3 1.2z"/>
            <path id="Layer" fill="#202d37"
                  d="m451.6 147.2q-12.4 0-22.6-2.4c-9.4-2.3-14.7-5.5-16.9-6.8-0.1-0.1-0.3-0.2-0.4-0.3-12-7.2-25-14.6-40.3-17.7-0.7-0.1-1.1-0.8-1-1.4 0.2-0.7 0.8-1.1 1.5-1 15.6 3.2 28.9 10.7 41.1 18q0.2 0.1 0.4 0.3c13.2 8.1 32.5 10.6 57.3 7.5 0.7-0.1 1.4 0.4 1.4 1.1 0.1 0.6-0.4 1.3-1 1.4q-10.3 1.3-19.5 1.3z"/>
            <path id="Layer" fill="#00B88B"
                  d="m348.1 112.9c-6.9-7.6-8.6-13.8-5.9-16.7 9.9-10.3 33.4-6.3 36 0.7 1.6 4.3-0.2 8.6-3.6 13.7-1.9 2.8-2.7 5.8-3 8.2-0.4 3.3 0.2 5.8 0.2 5.8-1.4 0.2-3.4 0.6-5.7 1.2 0 0-9.1 1.4-15.4-1.4l0.8-0.2c0.1-2.8 0.1-4.7-0.7-6.7-0.5-1.4-1.2-2.9-2.7-4.6z"/>
            <path id="Layer" fill="#202d37"
                  d="m356.3 152.1c-3 0-6.9-1-11.4-3.3-8.8-4.5-10.5-8.7-10.5-11.4 0-2.4 1.5-4.4 4.1-5.6 0.6-0.3 1.3 0 1.6 0.6 0.3 0.7 0 1.4-0.6 1.7-0.9 0.4-2.6 1.4-2.6 3.3 0 1.3 0.8 4.8 9.2 9.2 8.4 4.3 13.8 3.4 15.3 0.8 1-1.8 0.2-4.7-3.4-6.9-0.6-0.3-0.8-1.1-0.5-1.7 0.4-0.6 1.1-0.8 1.8-0.4 4.9 2.9 5.9 7.3 4.3 10.2-1.1 2.1-3.6 3.5-7.3 3.5z"/>
            <path id="Layer" fill="#202d37"
                  d="m354.8 159.6c-5.5 0-13.7-2.5-16.8-5.3-1.8-1.6-2.6-3.9-2.2-6.1 0.5-2.2 2.1-3.9 4.4-4.7 0.7-0.2 1.4 0.1 1.6 0.8 0.2 0.6-0.1 1.3-0.8 1.6-1.5 0.4-2.5 1.5-2.8 2.8-0.2 1.3 0.3 2.7 1.5 3.8 2.9 2.6 11.4 5 16.2 4.6 1.7-0.1 2.3-1.4 2.5-2.4 0.3-1.4-0.1-2.6-0.4-2.8-0.7-0.1-1.1-0.8-1-1.5 0.2-0.6 0.9-1.1 1.5-0.9 1.9 0.4 2.6 2.7 2.5 4.8-0.2 3-2.1 5.1-4.9 5.3q-0.6 0-1.3 0z"/>
            <path id="Layer" fill="#202d37"
                  d="m353.1 168.6c-2.8 0-5.9-0.6-8.9-2-4.5-2-7-4.5-6.9-7.2 0-1.2 0.6-3.5 4.5-5.1 0.6-0.2 1.3 0 1.6 0.7 0.2 0.6 0 1.3-0.7 1.6-1.9 0.8-2.9 1.8-3 2.8 0 1.2 1.5 3.2 5.5 5 6.1 2.7 11.9 2.1 13.4-0.1 0.8-1.3 0-3.2-2.2-5.4-0.5-0.4-0.5-1.2 0-1.7 0.5-0.5 1.3-0.5 1.7 0 4.4 4.1 3.5 7.1 2.6 8.4-1.3 2-4.2 3-7.6 3z"/>
            <path id="Layer" fill="#202d37"
                  d="m392.3 164.3c-0.1 0-0.1 0-0.2 0-15.3-1.7-22.7-8.7-24.4-23.2-4.2 0.1-15.2 0.1-17.8-3.7-0.5-0.7-1-2-0.2-3.6 3.4-7.8 22-10.6 24.2-10.8 0.6-0.1 1.3 0.4 1.3 1 0.2 0.7-0.3 1.3-1 1.4-5.4 0.7-19.9 3.8-22.3 9.3-0.3 0.7-0.1 1 0 1.3 1.6 2.2 10.8 2.8 16.8 2.5 0.7 0 1.3 0.5 1.4 1.1 1.3 14.4 7.6 20.6 22.3 22.2 0.7 0.1 1.2 0.7 1.1 1.4-0.1 0.7-0.6 1.1-1.2 1.1z"/>
            <path id="Layer" fill="#202d37"
                  d="m456.9 183.6c-20.5 0-38.2-8.3-44.7-11.2-1-0.5-2.1-1-2.4-1.1-0.6 0.1-2.5 0.9-4.2 1.6-5.3 2.2-14.2 6-21.9 5.6-0.6 0-1.2-0.6-1.1-1.3 0-0.7 0.6-1.2 1.3-1.2 7.1 0.3 15.6-3.2 20.7-5.4 3.2-1.3 4.6-1.9 5.5-1.8 0.5 0.1 1.1 0.4 3.1 1.3 7.5 3.5 30 13.9 54.2 10.2 0.7-0.1 1.4 0.3 1.5 1 0.1 0.7-0.4 1.3-1.1 1.4-3.7 0.6-7.3 0.9-10.9 0.9z"/>
            <path id="Layer" fill="#202d37"
                  d="m360.8 127.4c-3.6 0-8.4-0.5-12-2.6-0.6-0.3-0.8-1.1-0.5-1.7 0.4-0.6 1.1-0.8 1.7-0.5 5.8 3.4 15.8 2 15.9 1.9 0.7-0.1 1.3 0.4 1.5 1.1 0.1 0.6-0.4 1.3-1.1 1.4-0.2 0-2.4 0.4-5.5 0.4z"/>
            <path id="Layer" fill="#202d37"
                  d="m361.5 103.9q-1.8 0-3.8-0.2c-4.4-0.4-7.6-1.5-10-3.5-0.5-0.5-0.6-1.3-0.1-1.8 0.5-0.5 1.2-0.6 1.7-0.1 2 1.6 4.8 2.6 8.6 2.9 7.6 0.8 11.9-0.8 14.7-2.3 0.6-0.4 1.4-0.1 1.7 0.5 0.3 0.6 0.1 1.3-0.5 1.7-2.6 1.4-6.4 2.8-12.3 2.8z"/>
            <path id="Layer" fill="#202d37"
                  d="m376.9 239.8q0 3.5-1.5 5.6-1.5 2.2-4.8 3.2v6q0 0.3-0.2 0.5c-0.1 0.1-0.2 0.2-0.4 0.2h-8q-0.3 0-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-5.4c-1.5 0-2.9-0.1-4.1-0.3q-0.2 0-0.4-0.2-0.1-0.2-0.1-0.5l0.6-6.5q0-0.3 0.2-0.4 0.3-0.2 0.6-0.1 0.7 0.1 1.5 0.3 0.8 0.1 2 0.2 1.3 0.2 2.3 0.2c0.8 0 1.5-0.1 2-0.3q0.8-0.3 1.2-0.8c0.2-0.3 0.4-0.6 0.4-0.8 0.1-0.3 0.2-0.5 0.2-0.7q0-0.3-0.2-0.8c0-0.2-0.2-0.6-0.5-1-0.2-0.3-0.7-0.7-1.3-1.1-0.6-0.4-1.4-0.7-2.3-0.9-1-0.3-2-0.7-3-1.3q-1.4-0.8-2.6-2.1c-0.9-0.9-1.5-2-2-3.3-0.5-1.2-0.8-2.7-0.8-4.2q0-3.1 1.7-5.3c1.1-1.5 2.6-2.5 4.6-3.3v-6.2q0.1-0.3 0.2-0.5c0.1-0.1 0.3-0.2 0.5-0.2h8q0.3 0 0.5 0.2 0.2 0.2 0.2 0.5v5.4q2.1 0.1 4.3 0.3 0.2 0.1 0.4 0.3c0.1 0.1 0.2 0.2 0.1 0.4l-0.6 6.5q-0.1 0.2-0.3 0.5c-0.1 0.1-0.3 0.1-0.5 0.1q-3.3-0.8-6-0.8-1.1 0-2 0.3-0.8 0.3-1.2 0.8c-0.2 0.3-0.4 0.6-0.5 0.9 0 0.2-0.1 0.4-0.1 0.7 0 0.2 0 0.4 0.1 0.7 0.1 0.2 0.3 0.6 0.6 1q0.4 0.6 1.3 1.2c0.7 0.3 1.4 0.6 2.3 0.8q1.6 0.4 3 1.3 1.5 0.8 2.7 2.2 1.2 1.3 1.9 3.2c0.5 1.3 0.7 2.7 0.7 4.2z"/>
            <path id="Layer" fill-rule="evenodd" fill="#202d37"
                  d="m370 256.5h-8c-0.5 0-1-0.2-1.4-0.5-0.3-0.4-0.5-0.8-0.5-1.3v-4.3q-1.6-0.1-3-0.2-0.7-0.1-1.2-0.7c-0.3-0.4-0.5-0.8-0.5-1.3q0 0 0-0.1l0.7-6.4c0.1-0.6 0.3-1.1 0.8-1.4 0.4-0.3 1-0.4 1.5-0.3q0.7 0.2 1.5 0.3c0.5 0.1 1.1 0.2 1.9 0.3q1.2 0.1 2.2 0.1c0.6 0 1.1 0 1.5-0.2 0.4-0.2 0.5-0.3 0.6-0.4q0.2-0.4 0.3-0.5c0.1-0.2 0.1-0.3 0.1-0.3 0 0 0-0.2-0.1-0.4 0-0.1-0.1-0.3-0.4-0.7-0.1-0.1-0.3-0.4-0.9-0.7q-0.8-0.5-2-0.8c-1.1-0.3-2.2-0.7-3.3-1.4-1-0.6-2-1.4-2.9-2.3q-1.4-1.5-2.3-3.7-0.8-2.1-0.8-4.7c0-2.3 0.7-4.4 1.9-6.1 1.1-1.4 2.6-2.5 4.4-3.3v-5.4q0-0.7 0.5-1.3c0.4-0.4 0.9-0.6 1.4-0.6h8c0.5 0 1 0.2 1.4 0.5 0.3 0.4 0.5 0.9 0.5 1.4v4.2c1.1 0.1 2.1 0.2 3.2 0.3 0.4 0.1 0.9 0.3 1.1 0.6 0.4 0.4 0.6 1 0.5 1.5l-0.6 6.4v0.1c-0.1 0.5-0.4 0.9-0.8 1.2-0.4 0.4-1 0.5-1.5 0.4q-3.2-0.7-5.8-0.7c-0.6 0-1.1 0-1.5 0.2-0.5 0.1-0.6 0.3-0.6 0.3q-0.4 0.5-0.4 0.6 0 0.2 0 0.3c0 0 0 0.1 0 0.3 0.1 0.1 0.1 0.3 0.4 0.7 0.1 0.2 0.4 0.4 1 0.8q0.8 0.5 1.9 0.7 1.8 0.5 3.4 1.4c1 0.6 2 1.4 2.9 2.4q1.4 1.5 2.2 3.7c0.5 1.4 0.8 3 0.8 4.6 0 2.5-0.5 4.7-1.7 6.3-1 1.5-2.6 2.6-4.6 3.4v5.2c0 0.5-0.2 0.9-0.5 1.3-0.4 0.3-0.8 0.5-1.3 0.5zm-0.6-2.5v-5.4c0-0.6 0.3-1.1 0.9-1.2 1.9-0.6 3.3-1.5 4.1-2.7q1.3-1.9 1.3-4.9 0-2.1-0.7-3.8c-0.4-1.1-1-2-1.7-2.8q-1.1-1.2-2.4-1.9c-0.8-0.5-1.7-0.9-2.6-1.1q-1.5-0.4-2.7-1.1c-0.7-0.4-1.3-1-1.7-1.5-0.3-0.5-0.6-0.9-0.7-1.3q-0.2-0.6-0.2-1.1 0-0.5 0.2-1.1c0.2-0.4 0.4-0.7 0.7-1.2q0.5-0.8 1.7-1.2 1.1-0.4 2.4-0.4c1.7 0.1 3.6 0.3 5.6 0.7l0.5-5.2c-1.1-0.1-2.3-0.2-3.5-0.3-0.7 0-1.2-0.5-1.2-1.2v-4.9h-6.8v5.7c0 0.5-0.4 1-0.9 1.2-1.7 0.6-3.1 1.5-4 2.8-1 1.2-1.4 2.7-1.4 4.6q0 2 0.6 3.7c0.5 1.2 1.1 2.1 1.7 2.9q1.2 1.2 2.4 1.9 1.3 0.8 2.7 1.1 1.5 0.4 2.6 1c0.8 0.5 1.4 1 1.8 1.6 0.3 0.5 0.5 0.9 0.6 1.3q0.2 0.6 0.2 1.1 0 0.5-0.2 1.1c-0.1 0.4-0.3 0.8-0.6 1.2-0.4 0.5-1 1-1.7 1.2q-1.1 0.5-2.5 0.4c-0.7 0-1.5-0.1-2.4-0.1q-1.3-0.2-2.1-0.3c-0.3-0.1-0.6-0.1-0.9-0.2l-0.5 5.2c1 0.1 2.2 0.2 3.4 0.3 0.7 0 1.2 0.5 1.2 1.2v4.7zm4.8-37.8q0 0 0 0z"/>
            <path id="Layer" fill="#202d37"
                  d="m498.4 297.6q-0.1 0-0.1 0c-0.7-0.1-1.2-0.7-1.1-1.3 10.8-110.6 11.4-247.7 5.6-258.6-2.3-4.3-20-10.8-46.1-17-23.8-5.6-48.8-9.7-60.8-9.8-0.7 0-1.3-0.6-1.3-1.2 0-0.7 0.6-1.3 1.3-1.3 12.1 0.2 37.4 4.2 61.3 9.9 16.2 3.8 44 11.3 47.7 18.3 3.7 7 4.3 53.3 3.7 94.4-1 56.4-4.2 116.7-9 165.5 0 0.6-0.6 1.1-1.2 1.1z"/>
        </g>
        <g id="percent" className={'shape-2'}>
            <path id="Layer" fill="#202d37"
                  d="m306.2 102.5q-0.3 0-0.5-0.1c-0.6-0.3-0.9-1-0.6-1.7 0.1-0.3 13.5-27.9 18-37.5 0.3-0.6 1-0.9 1.6-0.6 0.6 0.3 0.9 1 0.6 1.6-4.4 9.6-17.8 37.3-18 37.6-0.2 0.5-0.6 0.7-1.1 0.7z"/>
            <path id="Layer" fill-rule="evenodd" fill="#202d37"
                  d="m320.5 98.8c-0.4 0-1-0.1-1.5-0.2-1.5-0.5-2.8-1.7-3.5-3.3-0.7-1.6-0.7-3.3-0.2-5.1 1.1-3.6 4.5-5.8 7.7-4.8 1.6 0.4 2.8 1.6 3.5 3.2 0.7 1.6 0.8 3.4 0.2 5.1-0.9 3.1-3.5 5.1-6.2 5.1zm1-11.2c-1.6 0-3.2 1.4-3.8 3.4-0.4 1.1-0.4 2.3 0.1 3.4 0.4 0.9 1.1 1.6 2 1.8 1.8 0.6 3.9-0.9 4.6-3.2 0.3-1.2 0.3-2.3-0.2-3.4-0.3-0.9-1.1-1.6-1.9-1.8q-0.4-0.2-0.8-0.2z"/>
            <path id="Layer" fill-rule="evenodd" fill="#202d37"
                  d="m305.9 81.2c-0.9 0-1.8-0.2-2.7-0.7-1.4-0.9-2.3-2.3-2.7-4-0.2-1.7 0.1-3.5 1-5 1.8-3.3 5.8-4.7 8.6-3 2.9 1.6 3.7 5.7 1.7 8.9-1.2 2.4-3.6 3.8-5.9 3.8zm1.5-10.9c-1.4 0-2.8 0.9-3.7 2.4-0.6 1.1-0.9 2.3-0.7 3.3 0.2 1 0.7 1.9 1.5 2.3 1.7 0.9 4 0 5.2-2.1 1.3-2.1 0.9-4.6-0.8-5.6q-0.7-0.3-1.5-0.3z"/>
            <path id="Layer" fill="#202d37"
                  d="m292.1 101.5c-0.7 0-1.2-0.5-1.2-1.2 0-0.4 0-10.2 0.6-13.6 0.1-0.6 0.8-1.1 1.4-1 0.7 0.1 1.2 0.8 1.1 1.4-0.6 3.1-0.6 13.1-0.6 13.2 0 0.7-0.6 1.2-1.3 1.2z"/>
            <path id="Layer" fill="#202d37"
                  d="m287.8 92.7c-0.7 0-1.2-0.5-1.3-1.2 0-0.6 0.5-1.2 1.2-1.3 2.8-0.3 9.4 0 9.7 0 0.7 0 1.2 0.6 1.2 1.3-0.1 0.6-0.6 1.2-1.3 1.2-0.1 0-6.7-0.3-9.4 0q-0.1 0-0.1 0z"/>
        </g>
    </svg>
}