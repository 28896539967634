import React from 'react';
import Wrapper from "../../atoms/Wrapper";
import Container from "../../atoms/Container";
import BlogCard from "../BlogCard";
import {PageProps} from "gatsby";
import Text from "../../atoms/Text";

type PROPS_STYLE={
    blogs:PageProps<Queries.HomeQuery['allSanityPost']['nodes'][0]>['data'][],
    searchTerm:string
}

export default function HomeBlogsList(props:PROPS_STYLE){
    return <Wrapper className={'bg-[#F8F9FA]'}>
        <Container className={'py-8 md:py-24'}>
            {props.searchTerm!==''&&props.blogs.length>0&&<div className={'py-5'}>
                <Text text={'Search Results for '} className={'h3'}/><br/>
                <Text text={props.searchTerm+':'}  className={'h2'}/>
            </div>}
            {props.blogs.length===0&&<div className={'py-5'}>
              <Text text={'No search Results for '} className={'h3'}/>
              <Text text={props.searchTerm+''}  className={'h3'}/>
            </div>}
            <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-14  '}>
                {props.blogs.map((b,i)=><BlogCard key={i} blog={b}/>)}

            </div>
        </Container>
    </Wrapper>
}
